import React from "react";
import { MathJax, MathJaxContext } from "better-react-mathjax";

export default function Math() {
  const config = {
    loader: { load: ["input/asciimath"] },
  };

  return (
    <MathJaxContext config={config}>
      <div className="math">
        <h2>Basic MathJax example with AsciiMath</h2>
        <MathJax>{"`frac(10)(4x) approx 2^(12)`"}</MathJax>
        <MathJax>{"`overline{AB}`"}</MathJax>
        <MathJax>{"`2x+5=10`"}</MathJax>
        <button>
          <MathJax>{"`2x+5=10`"}</MathJax>
        </button>
      </div>
    </MathJaxContext>
  );
}
