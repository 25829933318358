import React, { createContext, useState } from "react";

export const ScoreContext = createContext(null);

const ScoreProvider = ({ children }) => {
  const [score, setScore] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [currCount, setCurrCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [confettiShown, setConfettiShown] = useState(false);
  const goal = 10;

  const handleNext = () => {
    if (currCount < 5) {
      let count = currCount + 1;
      setCurrCount(count);
    } else {
      setCurrCount(0);
    }
  };

  // useEffect(() => {
  //   setScore(JSON.parse(localStorage.getItem("crud31")) || 0);
  // }, []);

  return (
    <ScoreContext.Provider
      value={{
        score,
        setScore,
        currCount,
        setCurrCount,
        incorrectCount,
        setIncorrectCount,
        handleNext,
        goal,
        isVisible,
        setIsVisible,
        confettiShown,
        setConfettiShown,
      }}
    >
      {children}
    </ScoreContext.Provider>
  );
};

export default ScoreProvider;
