import React from "react";
import { Link } from "react-router-dom";

const DrawingActivity = () => {
  return (
    <div className="drawing-activity">
      <Link to={`/`}>Home</Link>
      <h3>Drawing Activity</h3>
      <p>
        1) Draw two <strong style={{ color: "blue" }}>diagonal</strong> parallel
        lines that are both intersected by a{" "}
        <strong style={{ color: "green" }}>vertical</strong> line. Mark one pair
        of <strong style={{ color: "red" }}>corresponding</strong> angles.
      </p>
      <br />
      <p>
        2) Draw a triangle that has two <strong>perpendicular</strong> sides that have a the
        lengths of 3 cm, 4 cm, and a <strong style={{ color: "red" }}>hypotenuse</strong> of 5 cm. Find its perimeter.
      </p>
    </div>
  );
};

export default DrawingActivity;
