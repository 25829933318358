import { useContext, useState } from "react";
import { Reorder } from "framer-motion";
import { shuffleArray } from "../../utils/utils";
import { ScoreContext } from "../../context/ScoreProvider";
import AngleBisector from "../../assets/AngleBisector.svg";
import { useNavigate } from "react-router-dom";

const card = {
  id: 4,
  title: "The construction of an angle bisector is shown",
  image: AngleBisector,
  question: "Drag the steps into the correct order for this construction.",
  listItems: [
    { name: "Uno", id: 1 },
    { name: "Dos", id: 2 },
    { name: "Tres", id: 3 },
    { name: "Cuatro", id: 4 },
  ],
};

const listItems = [
  { name: "Draw ∠ABC and label the points.", id: 1 },
  {
    name: "Draw an arc that intersects the sides of ∠ABC and label the points of intersection J and K",
    id: 2,
  },
  { name: "Draw an arc from point J inside the angle", id: 3 },
  {
    name: "Without changing the compass setting, draw another arc inside the angle from point K. Name the point of intersection L.",
    id: 4,
  },
  { name: "Draw segment BL", id: 5 },
  { name: "∠ABL and ∠CBL are congruent angles.", id: 6 },
];
export default function Question5() {
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const {
    score,
    setScore,
    incorrectCount,
    setIncorrectCount,
    handleNext,
    setIsVisible,
    goal,
    confettiShown,
    setConfettiShown,
  } = useContext(ScoreContext);
  const [items, setItems] = useState(shuffleArray([...listItems]));

  const compareArrays = (a, b) =>
    a.every((element, index) => element === b[index]);

  const checkAnswer = (items) => {
    if (compareArrays(items, listItems)) {
      console.log("CORRECT");

      setScore(score + 1);
      handleNext();
    } else {
      console.log("incorrect");
      setMsg("Incorrect. Try again.");
      setTimeout(() => {
        setMsg("");
      }, 3000);

      setScore(score - 1);
      setIncorrectCount(incorrectCount + 1);
    }
    if (score >= goal - 1 && (confettiShown===false)) {
      setIsVisible(true);

      setTimeout(() => {
        console.log("navigate");
        setIsVisible(false);
        setConfettiShown(true);
        navigate(`/drawingactivity`);
      }, 5000);
    }
  };

  return (
    <div className="steps">
      <p style={{ fontSize: "10px" }}>{card.title}</p>
      <img style={{ height: "120px" }} src={card.image} alt="" />
      <p>{card.question}</p>
      <div className="choices">
        <Reorder.Group values={items} onReorder={setItems}>
          {items.map((item) => (
            // Change the li to Reorder.Item and add value prop
            <Reorder.Item key={item.id} value={item}>
              {item.name}
            </Reorder.Item>
          ))}
        </Reorder.Group>
        <button className="submit" onClick={() => checkAnswer(items)}>
          Submit
        </button>
      </div>

      <p style={{ color: "red", fontSize: "20px" }}>{msg}</p>
    </div>
  );
}
