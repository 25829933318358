import React from "react";
import { Route, Routes } from "react-router-dom";
// import PrivateRoutes from "./PrivateRoutes";
import Home from "./Home";
import DrawingActivity from "./DrawingActivity";
import Steps from "./Steps";
import Math from "./Math";


const RenderRoutes = () => {
  return (
    <div>
      <Routes>
        {/* <Route element={<PrivateRoutes />}>
           <Route exact path="/drawingactivity" element={<DrawingActivity />} />
          <Route exact path="/steps" element={<Steps />} />
          <Route exact path="/math" element={<Math />} /> 
        </Route> */}
        <Route exact path="/drawingactivity" element={<DrawingActivity />} />
        <Route exact path="/steps" element={<Steps />} />
        <Route exact path="/math" element={<Math />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
    </div>
  );
};

export default RenderRoutes;
