import React, { useContext, useState } from "react";
import { shuffleArray } from "../../utils/utils";
import { ScoreContext } from "../../context/ScoreProvider";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useNavigate } from "react-router-dom";

const Question4 = () => {
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const { score, setScore, incorrectCount, setIncorrectCount, handleNext, setIsVisible, goal,    confettiShown,
    setConfettiShown, } =
    useContext(ScoreContext);

  const config = {
    loader: { load: ["input/asciimath"] },
  };

  const card = {
    id: 3,

    question: "Find the value of x.",
    choices: ["2", "5", "7", "2.5"],
    answer: "2.5",
  };

  const checkAnswer = (answer) => {
    if (answer === card.answer) {
      console.log("CORRECT");

      setScore(score + 1);
      handleNext();
    } else {
      console.log("incorrect");
      setMsg("Incorrect. Try again.");
      setTimeout(() => {
        setMsg("");
      }, 3000);

      setScore(score - 1);
      setIncorrectCount(incorrectCount + 1);
    }
    if (score >= goal - 1 && (confettiShown===false)) {
      setIsVisible(true);

      setTimeout(() => {
        console.log("navigate");
        setIsVisible(false);
        setConfettiShown(true)
        navigate(`/drawingactivity`);
      }, 5000);
    }
  };

  return (
    <MathJaxContext config={config}>
      <div className="algebra">
        <p>{card.question}</p>
        <MathJax style={{ fontSize: "32px" }}>{"`2x+5=10`"}</MathJax>
        <br />
        <div className="choices">
          {shuffleArray(card.choices).map((el) => {
            return (
              <button key={el} onClick={() => checkAnswer(el)}>
                {el}
              </button>
            );
          })}
             </div>
          <br />
          <p style={{ color: "red", fontSize: "20px" }}>{msg}</p>
     
      </div>
    </MathJaxContext>
  );
};

export default Question4;
