import "./App.css";
import Header from "./components/Header";
import RenderRoutes from "./components/RenderRoutes";
import AuthProvider from "./context/AuthProvider";

import ScoreProvider from "./context/ScoreProvider";

function App() {
  return (
    <div className="App">
      <ScoreProvider>
        <AuthProvider>
          <Header className="header" />
          <div className="center">
            {/* <Navigation className="navigation" /> */}
            <RenderRoutes />
          </div>
        </AuthProvider>
      </ScoreProvider>
    </div>
  );
}

export default App;
