import { useState } from "react";
// import Reorder
import { Reorder } from "framer-motion";
import { shuffleArray } from "../utils/utils";
import CheckIcon from "./CheckIcon"

const listItems = [
  { name: "Uno", id: 1 },
  { name: "Dos", id: 2 },
  { name: "Tres", id: 3 },
  { name: "Cuatro", id: 4 },
];
export default function Steps() {
  //   const shuffledList = shuffleArray([...listItems]);
  const [items, setItems] = useState(shuffleArray([...listItems])); //shuffleArray(listItems)

  const compareArrays = (a, b) =>
    a.every((element, index) => element === b[index]);

  const checkAnswer = (items) => {
    console.log(items);
    console.log(listItems);
    if (compareArrays(items, listItems)) {
      console.log("Correct");
    } else {
      console.log("Incorrect");
    }
  };

  return (
    <div className="steps">
      {/* {console.log(items)} */}
      <Reorder.Group values={items} onReorder={setItems}>
        {items.map((item) => (
          // Change the li to Reorder.Item and add value prop
          <Reorder.Item key={item.id} value={item}>
            {item.name}
          </Reorder.Item>
        ))}
      </Reorder.Group>
      <button onClick={() => checkAnswer(items)}>Submit</button>
      <CheckIcon
        style={{
          backgroundColor: "green",
          color: "white",
        }}
      />
    </div>
  );
}
