import React, { useContext } from "react";
import { ScoreContext } from "../context/ScoreProvider";

const Header = ({ className }) => {
  const { score, incorrectCount } = useContext(ScoreContext);
  return (
    <nav className={className}>
      <h1>
        Geometry <span>Trainer</span>
      </h1>
      <h1>Incorrect: {incorrectCount}</h1>
      <h1>Score: {score} </h1>
    </nav>
  );
};

export default Header;
