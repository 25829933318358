import React, { useContext, useState } from "react";
import AngleABC from "../../assets/ABC.svg";
import { ScoreContext } from "../../context/ScoreProvider";
import { useNavigate } from "react-router-dom";

const getRandomNum = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

const Question6 = () => {
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const [randNum] = useState(getRandomNum(58, 70));
  // const randNum = getRandomNum(58, 70);
  const [answer, setAnswer] = useState("");
  const {
    score,
    setScore,
    incorrectCount,
    setIncorrectCount,
    handleNext,
    goal,
    setIsVisible,
    confettiShown,
    setConfettiShown,
  } = useContext(ScoreContext);
  const card = {
    id: 0,
    title: "∠ABC is shown below.",
    title2: "Segment BD is the angle of bisector of ∠ABC.",
    title3: `The measure of ∠ABC = ${randNum}°`,
    image: AngleABC,
    question: "What is the meaure of ∠DBC?",
  };

  const checkAnswer = () => {
    if (answer === (randNum / 2).toString()) {
      console.log("CORRECT");
      setScore(score + 1);
      handleNext();
    } else {
      console.log("incorrect");
      setMsg("Incorrect. Try again.");
      setTimeout(() => {
        setMsg("");
      }, 3000);

      setScore(score - 1);
      setIncorrectCount(incorrectCount + 1);
    }

    if (score >= goal - 1 && confettiShown === false) {
      setIsVisible(true);

      setTimeout(() => {
        console.log("navigate");
        setIsVisible(false);
        setConfettiShown(true);
        navigate(`/drawingactivity`);
      }, 5000);
    }
  };

  return (
    <div className="formula">
      <p style={{ fontSize: "10px" }}>{card.title}</p>
      <p style={{ fontSize: "16px" }}>{card.title2}</p>
      <p style={{ fontSize: "20px", fontWeight: "bold" }}>{card.title3}</p>
      <img style={{ height: "200px" }} src={card.image} alt="" />
      <p style={{ fontSize: "20px", fontWeight: "bold" }}>{card.question}</p>
      <br />
      <input
        placeholder="Enter answer"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <button className="submit" onClick={checkAnswer}>
        Submit
      </button>
      <br />
      <p style={{ color: "red", fontSize: "20px" }}>{msg}</p>
    </div>
  );
};

export default Question6;
