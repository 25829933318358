import React, { useContext, useState } from "react";
import CopyingAnAngle from "../../assets/CopyingAnAngle.svg";
import { shuffleArray } from "../../utils/utils";
import { ScoreContext } from "../../context/ScoreProvider";
import { useNavigate } from "react-router-dom";

const Question2 = () => {
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const {
    score,
    setScore,
    incorrectCount,
    setIncorrectCount,
    handleNext,
    setIsVisible,
    goal,
    confettiShown,
    setConfettiShown,
  } = useContext(ScoreContext);
  const card = {
    id: 1,
    title: "This diagram represents a partially completed construction.",
    image: CopyingAnAngle,
    question: "Which of the following is created by the final construction?",
    choices: [
      "Parallel Lines",
      "Perpendicular Lines",
      "Angle Bisector",
      "Congruent Angles",
    ],
    answer: "Congruent Angles",
  };

  const checkAnswer = (answer) => {
    if (answer === card.answer) {
      console.log("CORRECT");
      setScore(score + 1);
      handleNext();
    } else {
      console.log("incorrect");
      setMsg("Incorrect. Try again.");
      setTimeout(() => {
        setMsg("");
      }, 3000);

      setScore(score - 1);
      setIncorrectCount(incorrectCount + 1);
    }
    if (score >= goal - 1 && (confettiShown===false)) {
      setIsVisible(true);

      setTimeout(() => {
        console.log("navigate");
        setIsVisible(false);
        setConfettiShown(true);
        navigate(`/drawingactivity`);
      }, 5000);
    }
  };

  return (
    <div className="multiple-choice">
      <p style={{ fontSize: "10px" }}>{card.title}</p>
      <img src={card.image} alt="" />
      <p>{card.question}</p>
      <br />
      <div className="choices">
        {shuffleArray(card.choices).map((el) => {
          return (
            <button key={el} onClick={() => checkAnswer(el)}>
              {el}
            </button>
          );
        })}
      </div>

      <br />
      <p style={{ color: "red", fontSize: "20px" }}>{msg}</p>
    </div>
  );
};

export default Question2;
