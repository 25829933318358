import React, { useContext} from "react";
import Demo from "./Confetti";
import Question1 from "./questions/Question1";
import Question2 from "./questions/Question2";
import Question3 from "./questions/Question3";
import { ScoreContext } from "../context/ScoreProvider";
import Question4 from "./questions/Question4";
import Question5 from "./questions/Question5";
import Question6 from "./questions/Question6";

const Home = () => {
  const { currCount, isVisible } =
    useContext(ScoreContext);
  const questions = [
    {
      id: 0,
      component: <Question1 />,
    },
    {
      id: 1,
      component: <Question2 />,
    },
    {
      id: 2,
      component: <Question3 />,
    },
    {
      id: 3,
      component: <Question4 />,
    },
    {
      id: 4,
      component: <Question5 />,
    },
    {
      id: 5,
      component: <Question6 />,
    },
  ];

  return (
    <div className="home">
      {/* <button onClick={() => setIsVisible(!isVisible)}>Fire</button> */}
      {isVisible && <Demo />}

      {/* <p>index: {questions[currCount].id}</p> */}
      {questions[currCount].component}

      <br />
      {/* <button className="submit" onClick={handleNext}>Next</button> */}
    </div>
  );
};

export default Home;
