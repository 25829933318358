import React, { useContext, useState } from "react";
import PerpBisector from "../../assets/PerpBisectorSoccerFieldToPlayground.svg";
import { shuffleArray } from "../../utils/utils";
import { ScoreContext } from "../../context/ScoreProvider";
import DropDownMenu from "../DropDownMenu";
import { useNavigate } from "react-router-dom";

const Question3 = () => {
  const navigate = useNavigate();
  const [selectedOption1, setSelectedOption1] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [msg, setMsg] = useState("");
  const {
    score,
    setScore,
    incorrectCount,
    setIncorrectCount,
    handleNext,
    setIsVisible,
    goal,
    confettiShown,
    setConfettiShown,
  } = useContext(ScoreContext);
  const card = {
    id: 2,
    title:
      "",
    image: PerpBisector,
    question:
      "The map of a city park is shown below. A hot dog vendor wants to set up their cart so that it is the same distance away from the playground and the soccer field in order to maximize the number of customers.Complete the statement below to indicate which construction he should use to find where the hot dog cart can be set up.",
    options1: ["perpendicular bisector", "angle bisector", "copy an angle"],
    options2: ["endpoints", "sides", "angle"],
    answer1: "perpendicular bisector",
    answer2: "endpoints",
  };

  const checkAnswer = (answer) => {
    if (selectedOption1 === card.answer1 && selectedOption2 === card.answer2) {
      console.log("CORRECT");
      setMsg("Correct");
      setScore(score + 1);
      handleNext();
    } else {
      console.log("incorrect");

      setMsg("Incorrect. Try again.");
      setTimeout(() => {
        setMsg("");
      }, 3000);

      setScore(score - 1);
      setIncorrectCount(incorrectCount + 1);
    }
    if (score >= goal - 1 && (confettiShown===false)) {
      setIsVisible(true);

      setTimeout(() => {
        console.log("navigate");
        setIsVisible(false);
        setConfettiShown(true)
        navigate(`/drawingactivity`);
      }, 5000);
    }
  };

  return (
    <div className="fill-in-the-blank">
      <p style={{ fontSize: "10px" }}>{card.title}</p>
      <p>{card.question}</p>
      <img
        style={{ height: "120px", margin: "20px" }}
        src={card.image}
        alt=""
      />

      <br />
      <div className="choices">
        <p>
          To determine the location, the vendor must perform the{" "}
          <DropDownMenu
            options={shuffleArray(card.options1)}
            selectedOption={selectedOption1}
            setSelectedOption={setSelectedOption1}
            title="Select Answer"
          />{" "}
          construction because all points on this construction are equidistant
          from the{" "}
          <DropDownMenu
            options={shuffleArray(card.options2)}
            selectedOption={selectedOption2}
            setSelectedOption={setSelectedOption2}
            title="Select Answer"
          />
          .
        </p>
        <button className="submit" style={{ marginTop: "30px" }} onClick={checkAnswer}>
          Submit
        </button>
        <p style={{ color: "red", fontSize: "20px" }}>{msg}</p>
      </div>

      <br />
    </div>
  );
};

export default Question3;
